import { all } from 'redux-saga/effects'

import authSagas from './auth';
import videoSagas from './video';
import pageLoadSagas from './pageLoad';
import interactionSagas from './interaction';
import userSage from './user';
import activitySagas from './activity';
// import masterSagas from './masters';

export default function* IndexSagas() {
  yield all([
    pageLoadSagas(),
    authSagas(),
    videoSagas(),
    interactionSagas(),
    userSage(),
    activitySagas()
  ])
}