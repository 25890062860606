import { take, call, put, cancel, takeLatest, fork, all } from 'redux-saga/effects';
import { userList, userError, updateUserList, createUserList } from '../actions/user';
import { FETCH_USER_LIST, CREATE_USER, UPDATE_USER, FETCH_SINGLE_USER, USER_LIST } from '../constant/actionType/user';
import * as userApi from '../service/user';
import { ToastContainer, toast } from 'react-toastify';


function* fetchSingleUserSaga(action) {
  // try {
  //   const { email, password } = action.payload
  //   const response = yield call(AuthenticationService.login, { email, password });
  //   if (response.status == 200) {
  //     yield put(loginSuccess())
  //   }
  // } catch (error) {
  //   console.trace(error);
  // }
}

function* fetchUserListSaga(action) {
  
  try {
    let params;
    if (action.payload){
      params = action.payload
    }else{
      params = {
        page: 1,
        perPage: 50
      }
    }

    const response = yield call(userApi.getUserList, params);
    if (response.status == 200) {
      const payload = {
        data: response.data.data,
        message: 'success'
      }
      yield put(userList(payload))
    }else{
      const payload = {
        message: 'Could not load user list'
      }
      yield put(userError(payload))
    }
  } catch (error) {
    console.trace(error);
  }
}

function* updateUserSaga(action) {
  try {
    let params = action.payload;

    const response = yield call(userApi.updateUserData, params);
      if (response.status == 200) {
        const payload = {
          message: 'success',
          data: params
        }
        toast.success('Updated Successfully')
        yield put(updateUserList(payload))
      }else{
      const payload = {
        message: 'Could not load user list'
      }
      toast.error('Could not update user')
      yield put(userError(payload))
    }
  } catch (error) {
    console.trace(error);
  }
}

function* createUserSaga(action) {
  try {
    let params = action.payload;

    const response = yield call(userApi.createUserData, params);
      if (response.status == 200) {
        const payload = {
          message: 'success',
          data: response.data.data
        }
        yield put(createUserList(payload))
      }else{
      const payload = {
        message: 'Could not load user list'
      }
      yield put(userError(payload))
    }
  } catch (error) {
    console.trace(error);
  }
}

export default function* actionWatcher() {
  yield all([
    takeLatest(FETCH_USER_LIST, fetchUserListSaga),
    takeLatest(UPDATE_USER, updateUserSaga),
    takeLatest(CREATE_USER, createUserSaga),
    takeLatest(FETCH_SINGLE_USER, fetchSingleUserSaga)
  ])
}
