import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL, FILE_URL } from '../constant/actionTypes'
const currentUserSubject = new BehaviorSubject(localStorage.getItem('alacaid'));
const currentUserGroup = new BehaviorSubject(localStorage.getItem('alacagid'));

const AuthenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    get currentUserRole () { return currentUserGroup.value }
};

export default AuthenticationService;

async function login({email, password}) {
    const loginData = await axios.post(`${API_URL}auth/login`, {email, password}, { headers: { 'Content-Type': 'application/json' } } );
    if (loginData.status == 200){
        const userId = loginData.data.data.id ? loginData.data.data.id : loginData.data.data._id
        localStorage.setItem('alacaid', userId);
        localStorage.setItem('alacagid', loginData.data.data.role);
        localStorage.setItem('token', loginData.data.data.token);
        currentUserSubject.next(userId);
    }
    return loginData;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}