import Axios from "axios";
import { apiHeader } from "./apiServiceConfig";
import { API_URL } from "../constant/actionTypes";

export const getSingleUser = (data) => {
  return Axios.post(`${API_URL}user/list`,data ,apiHeader )
                  .then(response => response)
                  .catch((error) => console.trace(error));
}

export const getUserList = (data) => {
    return Axios.post(`${API_URL}user/lists`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const updateUserData = (data) => {
    return Axios.post(`${API_URL}user/update`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

export const createUserData = (data) => {
  return Axios.post(`${API_URL}auth/register`,data ,apiHeader )
                  .then(response => response)
                  .catch((error) => console.trace(error));
}