import { CREATE_USER_LIST, UPDATE_USER_LIST, FETCH_USER_LIST, CREATE_USER, UPDATE_USER,
   USER_LIST, FETCH_SINGLE_USER, USER_ERROR } from "../constant/actionType/user";

const initial_state = {
    loading: false,
    isError: false,
    dataLoaded: false,
    dataUpdated: false,
    message:"",
    userList: [],
    total: 0
};

export default (state = initial_state, action) => {
    switch (action.type) {
      case UPDATE_USER_LIST:
        let userList = [...state.userList];
        for (const [index, item] of userList.entries()){
          if (item._id == action.payload.data.userId){
            userList[index] = {...item, ...action.payload.data }
          }
        }

        return { 
          ...state,
          loading: false,
          isError: false,
          dataLoaded: false,
          dataUpdated: true,
          message: action.payload.message,
          userList: [...userList]
        };

      case CREATE_USER_LIST:
        return { 
          ...state,
          loading: false,
          isError: false,
          dataUpdated: true,
          dataLoaded: false,
          message: action.payload.message,
          userList: [...state.userList, {...action.payload.data}]
        };

      case USER_LIST:
        return { 
          ...state,
          loading: false,
          isError: false,
          dataUpdated: false,
          dataLoaded: true,
          message: action.payload.message,
          userList: [...action.payload?.data?.users],
          total: action.payload?.data?.total
        };

        case FETCH_USER_LIST:
        return { 
          ...state,
          loading: true,
          isError: false,
          dataUpdated: false,
          dataLoaded: false,
        };

        case UPDATE_USER:
        return { 
          ...state,
          loading: true,
          isError: false,
          dataUpdated: false,
          dataLoaded: false,
        };

        case CREATE_USER:
        return { 
          ...state,
          loading: true,
          isError: false,
          dataUpdated: false,
          dataLoaded: false,
        };

        case FETCH_SINGLE_USER:
        return { 
          ...state,
          loading: true,
          isError: false,
          dataUpdated: false,
          dataLoaded: false,
        };

        case USER_ERROR:
        return { 
          ...state,
          loading: false,
          isError: true,
          dataUpdated: false,
          dataLoaded: false,
          message: action.payload.message,
        };

        default: return { ...state };
    }
}
