/*
 *
 * Home Page Saga
 *
 */
import { put, takeLatest, all, call } from 'redux-saga/effects';
import Action, { ActionTypes } from '../actions/video';
import * as userAction from '../actions/user';
import * as userActivity from '../actions/activity';
import * as InteractionAction from '../actions/interaction';

export function* handleLoadPage(action) {
  const { location } = action.payload;
  if (location.pathname.indexOf('/videoBot') !== -1) {

    // const videoBotId = location.pathname.split('/videoBot/')[1];
    const videoBotId = location.pathname.split('/')[2];
    if (videoBotId && videoBotId!='')
        yield put(Action.fetchAgentVideos(videoBotId));
    // else
        // yield put(Action.fetchAgentVideos('5e6a2b39636235af830fe5ab'));
    
  }else if( location.pathname.indexOf('/interaction') !== -1 ){
    const interactionId = location.pathname.split('/interaction/')[1];
    if (localStorage.getItem('alacagid') == "user"){
      if (interactionId && interactionId !=''){
          yield put(InteractionAction.fetchSingleInteraction(interactionId));
      }else
          yield put(InteractionAction.fetchUserInteraction());
    }
  }else if( location.pathname.indexOf('/user') !== -1 ){

    const userId = location.pathname.split('/user/')[1];
    if (userId && userId != '')
        yield put(userAction.fetchSingleUser(userId));
    else
        yield put(userAction.fetchUserList());
    
  }else if( location.pathname.indexOf('/activity-bot') !== -1 ){
    const botId = location.pathname.split('/activity-bot/')[1]
    var params = {};
      var parser = document.createElement('a');
      parser.href = window.location.href;
      var query = parser.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
        }
    let page = parseInt(params.page) > 0 ? parseInt(params.page) : 1
    let payload = { botId: botId, page: page, perPage:30}
      yield put(userActivity.botActivity(payload));
    
  }
}

export default function* actionWatcher() {
  yield all([
     takeLatest('@@router/LOCATION_CHANGE', handleLoadPage)
  ]);
  }