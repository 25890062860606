import { CREATE_INTERACTION, CREATE_INTERACTION_LIST, FETCH_INTERACTION_LIST, FETCH_INTERACTION,
  AUTO_GENERATE_CONTENT, FETCH_USER_INTERACTION, FETCH_USER_INTERACTION_LIST, UPDATE_INTERACTION,
UPDATE_INTERACTION_LIST, INTERACTION_ERROR, UPDATE_CONTENT, UPDATE_CONTENT_LIST, CREATE_CONTENT, CREATE_CONTENT_LIST } from "../constant/actionType/interaction";

const initialState = {
  loading: false,
  updating: false,
  isError: false,
  dataLoaded: false,
  dataUpdated: false,
  message:"",
  interactions: [],
  userInteractions: [],
};

const interaction = (state = initialState, action) => {
  switch (action.type) {
    
    case INTERACTION_ERROR:
      return {
        ...state,
        loading: false,
        updating:false,
        isError: true,
        dataLoaded: false,
        dataUpdated: false,
        message: action.payload.message
      };

      case AUTO_GENERATE_CONTENT:

        return {
          ...state,
          updating:true,
          loading: false,
          isError: false,
          dataLoaded: false,
          dataUpdated: false,
          message: ""
        };

    case FETCH_USER_INTERACTION:
      return {
        ...state,
        loading: true,
        updating:false,
        isError: false,
        dataUpdated: false,
        dataLoaded: false,
        message: ""
      };

    case FETCH_USER_INTERACTION_LIST:
      return {
        ...state,
        loading: false,
        updating:false,
        isError: false,
        dataUpdated: false,
        dataLoaded: true,
        message: action.payload.message,
        userInteractions: [...action.payload.data.interaction],
        user: action.payload.data.user,
      };
        
    case FETCH_INTERACTION_LIST:
      return {
        ...state,
        loading: false,
        updating:false,
        isError: false,
        dataUpdated: false,
        dataLoaded: true,
        message: action.payload.message,
        userInteractions: [...action.payload.data]
      };

    case FETCH_INTERACTION:
      return {
        ...state,
        loading: true,
        isError: false,
        updating:false,
        dataUpdated: false,
        dataLoaded: false,
        message:"",
      };

    case UPDATE_INTERACTION_LIST:

      let tempData = [...state.userInteractions];
      for (const [index, item] of tempData.entries()){
        for (const [ind, interactionItem] of item.interaction.entries()){
          if(interactionItem._id == action.payload.id){
            tempData[index].interaction[ind] = {...interactionItem, ...action.payload.data }
          }
        }
      }

      return {
        ...state,
        loading: false,
        updating:false,
        isError: false,
        dataUpdated: true,
        dataLoaded: false,
        message:action.payload.message,
        userInteractions: [...tempData]
      };

    case UPDATE_INTERACTION:
      return {
        ...state,
        loading: false,
        updating:true,
        isError: false,
        dataUpdated: false,
        dataLoaded: false,
        message:"",
      };

    case CREATE_INTERACTION_LIST:
      let tempcreateData = [...state.userInteractions];
      for (const [index, item] of tempcreateData.entries()){

        if (item._id == action.payload.id){
            tempcreateData[index].interaction = [...(item.interaction || [] ), ...action.payload.data ]
        }
      }

      return {
        ...state,
        loading: false,
        updating:false,
        isError: false,
        dataUpdated: true,
        dataLoaded: false,
        message:action.payload.message,
        userInteractions: [...tempcreateData]
      };

    case CREATE_INTERACTION:
      return {
        ...state,
        loading: false,
        updating:true,
        isError: false,
        dataUpdated: false,
        dataLoaded: false,
        message:"",
      };

    case UPDATE_CONTENT_LIST:

      let tempContent = [...state.userInteractions];

      for (const [index, item] of tempContent.entries()){
        if (item._id == action.payload.data._id){
          tempContent[index] = {...item, ...action.payload.data }
        }
      }
    return {
      ...state,
      loading: false,
      isError: false,
      updating:false,
      dataUpdated: true,
      dataLoaded: false,
      message: action.payload.message,
      userInteractions: [...tempContent]
    };

    case UPDATE_CONTENT:
      return {
        ...state,
        loading: false,
        updating:true,
        isError: false,
        dataUpdated: false,
        dataLoaded: false,
        message:""
      };

    case CREATE_CONTENT_LIST:
      return {
        ...state,
        loading: false,
        updating:false,
        isError: false,
        dataUpdated: true,
        dataLoaded: false,
        message:action.payload.message,
        userInteractions: [...state.userInteractions,{...action.payload.data}]
      };

    case CREATE_CONTENT:
      return {
        ...state,
        loading: false,
        updating:true,
        isError: false,
        dataUpdated: false,
        dataLoaded: false,
        message:"",
      };

    default:
      return state;
  }
};

export default interaction;
