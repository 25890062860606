// import { combineReducers } from 'redux';
// import authReducer from './authReducer';
// import interactionReducer from './interactionReducer';

// export default combineReducers({
//   auth: authReducer,
//   interactions: interactionReducer
// });

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../utils/history';
import Auth from './auth';
import VideoReducer from './videoReducer';
import User from './user';
import interaction from './interactionReducer';
import activity from './activity';

export default combineReducers({
  Auth,
  VideoReducer,
  User,
  interaction,
  activity,
  router: connectRouter(history)
});