// ActionTypes for User
export const CREATE_INTERACTION ="app/interaction/CREATE_INTERACTION";
export const CREATE_INTERACTION_LIST ="app/interaction/CREATE_INTERACTION_LIST";
export const FETCH_INTERACTION ="app/interaction/FETCH_INTERACTION";
export const FETCH_INTERACTION_LIST ="app/interaction/FETCH_INTERACTION_LIST";
export const FETCH_SINGLE_INTERACTION ="app/interaction/FETCH_SINGLE_INTERACTION";
export const UPDATE_INTERACTION ="app/interaction/UPDATE_INTERACTION";
export const UPDATE_INTERACTION_LIST ="app/interaction/UPDATE_INTERACTION_LIST";
export const INTERACTION_ERROR ="app/interaction/INTERACTION_ERROR";
export const FETCH_USER_INTERACTION ="app/interaction/FETCH_USER_INTERACTION";
export const FETCH_USER_INTERACTION_LIST ="app/interaction/FETCH_USER_INTERACTION_LIST";
export const CREATE_CONTENT ="app/interaction/CREATE_CONTENT";
export const CREATE_CONTENT_LIST ="app/interaction/CREATE_CONTENT_LIST";
export const UPDATE_CONTENT ="app/interaction/UPDATE_CONTENT";
export const UPDATE_CONTENT_LIST ="app/interaction/UPDATE_CONTENT_LIST";
export const AUTO_GENERATE_CONTENT ="app/interaction/AUTO_GENERATE_CONTENT";
export const CREATE_ENQUIRY_FIELDS ="app/interaction/CREATE_ENQUIRY_FIELDS";
export const RECORD_ANSWERED_QUESTIONS ="app/interaction/RECORD_ASKED_QUESTIONS";
export const RECORD_UNAVAILABLE_QUESTIONS ="app/interaction/RECORD_UNAVAILABLE_QUESTIONS";
export const RECORD_CONTACT_ATTEMPT ="app/interaction/RECORD_CONTACT_ATTEMPT";
export const RECORD_ENQUIRY_FORM_SUBMISSION ="app/interaction/RECORD_ENQUIRY_FORM_SUBMISSION";
export const RECORD_VIDEOBOT_VIEWS ="app/interaction/RECORD_VIDEOBOT_VIEWS";
export const RECORD_VIDEOBOT_WATCHED_DURATION ="app/interaction/RECORD_VIDEOBOT_WATCHED_DURATION";
export const GET_RECORDED_ANSWERED_QUESTIONS ="app/interaction/GET_RECORDED_ANSWERED_QUESTIONS";
export const GET_RECORDED_UNAVAILABLE_QUESTIONS ="app/interaction/GET_RECORDED_UNAVAILABLE_QUESTIONS";
export const GET_RECORDED_CONTACT_ATTEMPT ="app/interaction/GET_RECORDED_CONTACT_ATTEMPT";
export const GET_RECORDED_VIDEOBOT_WATCHED_DURATION ="app/interaction/GET_RECORDED_VIDEOBOT_WATCHED_DURATION";
export const GET_RECORDED_VIDEOBOT_VIEW_COUNT ="app/interaction/GET_RECORDED_VIDEOBOT_VIEW_COUNT";
export const GET_RECORDED_ENQUIRY_FORM_SUBMISSION ="app/interaction/GET_RECORDED_ENQUIRY_FORM_SUBMISSION";