export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";
// export const FILE_URL = ‘https://b2bexpertrons-test.s3.ap-south-1.amazonaws.com/’;
// export const FileUrl = ‘https://b2bexpertrons.s3.ap-south-1.amazonaws.com/’;
let API_LINK, SENTENCE_SIM_LINK, SOCKET_LINK, SENTRY_ENVIRONMENT, FileUrl;
if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development") {
  API_LINK = process.env.REACT_APP_API_TEST;
  FileUrl = process.env.REACT_APP_FILE_URL_TEST;
  // BASE_LINK = ‘http://localhost/’
  SENTENCE_SIM_LINK = process.env.REACT_APP_RECOMMENDATION_URL;
  SOCKET_LINK = process.env.REACT_APP_SOCKET_URL_TEST;
  SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT_TEST;
} else {
  API_LINK = process.env.REACT_APP_API_LIVE;
  FileUrl = process.env.REACT_APP_FILE_URL_LIVE;
  SENTENCE_SIM_LINK = process.env.REACT_APP_RECOMMENDATION_URL_LIVE;
  SOCKET_LINK = process.env.REACT_APP_SOCKET_URL_LIVE;
  SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT_LIVE;
}
// export const API_URL = API_LINK;
// export const API_LINK = ‘http://b2b.expertrons.com/’;
// export const API_URL = ‘http://13.126.115.206/api/’;
// export const API_URL = ‘http://3.6.135.61/api/’;
export const API_URL = API_LINK;
export const FILE_URL = FileUrl;
export const SENTENCE_SIM_URL = SENTENCE_SIM_LINK;
export const SOCKET_URL = SOCKET_LINK;
export const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const REACT_APP_SENTRY_ENVIRONMENT = SENTRY_ENVIRONMENT;
export const REACT_APP_LOW_VIDEO_QUALITY_CONFIGURATION = process.env.REACT_APP_LOW_VIDEO_QUALITY_CONFIGURATION;
export const REACT_APP_MEDIUM_VIDEO_QUALITY_CONFIGURATION = process.env.REACT_APP_MEDIUM_VIDEO_QUALITY_CONFIGURATION;
export const REACT_APP_HIGH_VIDEO_QUALITY_CONFIGURATION = process.env.REACT_APP_HIGH_VIDEO_QUALITY_CONFIGURATION;
export const REACT_APP_LOW_VIDEO_QUALITY = process.env.REACT_APP_LOW_VIDEO_QUALITY;
export const REACT_APP_MEDIUM_VIDEO_QUALITY = process.env.REACT_APP_MEDIUM_VIDEO_QUALITY;
export const REACT_APP_HIGH_VIDEO_QUALITY = process.env.REACT_APP_HIGH_VIDEO_QUALITY;
