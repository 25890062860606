import React, { Fragment, useEffect, lazy, Suspense } from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
// import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
// import history from 'utils/history';
import history from "./utils/history";

//imports for sentry 
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { REACT_APP_SENTRY_DNS, REACT_APP_SENTRY_ENVIRONMENT } from './constant/actionTypes';
import Index from "./components/analytics";
import DetailTable from "./components/counts/detailTable";

// Import custom Components
// import Registration from "./components/forms/Registration";
// // import AdminLogin from "./components/forms/AdminLogin";
// import Login from "./components/forms/Login";
// import Interactions from "./components/interactions";
// import App from "./components/app";
// import PrivateRoute from './components/PrivateRoute';
// import VideoBot from "./components/videoBot";
// import VideoBotStyleCont from "./components/videoBot/VideoBotStyleCont";

// import AllUsers  from "./components/users/allUsers";
// import UserEdit from "./components/users/userEdit";
// import CreateUserDetail from "./components/users/createUserDetail";
// import EditInteraction from "./components/interactions/editInteraction";
// // import EmbededTest from "./components/embededTest";
// import Activity from "./components/activity";
// import ActivitySelected from "./components/activity/activitySelected";
// import ActivityBot from "./components/activity/activityBot";

const Registration = lazy(() => import("./components/forms/Registration"));
const Login = lazy(() => import("./components/forms/Login"));
const Interactions = lazy(() => import("./components/interactions"));
const App = lazy(() => import("./components/app"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const VideoBot = lazy(() => import("./components/videoBotV2"));
const VideoBotStyleCont = lazy(() => import("./components/videoBot/VideoBotStyleCont"));
const AllUsers = lazy(() => import("./components/users/allUsers"));
const UserEdit = lazy(() => import("./components/users/userEdit"));
const CreateUserDetail = lazy(() => import("./components/users/createUserDetail"));
const EditInteraction = lazy(() => import("./components/interactions/editInteraction"));
const Activity = lazy(() => import("./components/activity"));
const ActivitySelected = lazy(() => import("./components/activity/activitySelected"));
const ActivityBot = lazy(() => import("./components/activity/activityBot"));
const FormResponses = lazy(() => import("./components/activity/formResponses"));
const UnavailableQuestions = lazy(() => import("./components/interactions/UnavailableQuestions"));


Sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }
  render() {
    // if (this.state.hasError) {
    //   return <h1>Something went wrong.</h1>
    // }
    return this.props.children;
  }
}


//firebase Auth
function Root() {

  useEffect(() => {
    const themeColor = localStorage.getItem("theme-color");
    const layout = localStorage.getItem("layout_version");
    // document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${themeColor}.css`);
    document.body.classList.add(layout);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="App">
        <Provider store={store}>
          {/* <BrowserRouter basename={"/"}> */}
          <ConnectedRouter history={history}>
            <ScrollContext>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/videoBot" component={VideoBot} />
                <Route path="/style/videoBot" component={VideoBotStyleCont} />
                <Fragment>
                  <ErrorBoundary>
                    <App>
                      <PrivateRoute path="/register" component={Registration} />
                      <PrivateRoute path="/interaction" component={Interactions} />
                      <PrivateRoute path="/editInteraction" component={EditInteraction} />
                      <PrivateRoute path="/user" component={AllUsers} />
                      <PrivateRoute path="/editUser" component={UserEdit} />
                      <PrivateRoute path="/createUser" component={CreateUserDetail} />
                      <PrivateRoute path="/activity" component={Activity} />
                      <PrivateRoute path="/activitySelected" component={ActivitySelected} />
                      <PrivateRoute path="/activity-bot" component={ActivityBot} />
                      <PrivateRoute path="/form-responses" component={FormResponses} />
                      <PrivateRoute path="/form-analytics" component={Index} />
                      <PrivateRoute path="/details" component={DetailTable} />
                      <PrivateRoute path="/unavailable-questions" component={UnavailableQuestions} />
                    </App>
                  </ErrorBoundary>
                </Fragment>
              </Switch>
            </ScrollContext>
          </ConnectedRouter>
          {/* </BrowserRouter> */}
        </Provider>
      </div>
    </Suspense>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

//serviceWorker.unregister();
