export const ActionTypes = {
    QUESTION_ASKED: 'container/HomePage/QUESTION_ASKED',
    QUESTION_CHANGE: 'container/HomePage/QUESTION_CHANGE',
    QUESTION_IDLE: 'container/HomePage/QUESTION_IDLE',
    UPDATE_QUESTION: 'container/HomePage/UPDATE_QUESTION',
    FETCH_AGENT_VIDEOS_SUCCESS: 'container/HomePage/FETCH_AGENT_VIDEOS_SUCCESS',
    FETCH_AGENT_VIDEOS_FAILED: 'container/HomePage/FETCH_AGENT_VIDEOS_FAILED',
    FETCH_AGENT_VIDEOS: 'container/HomePage/FETCH_AGENT_VIDEOS',
    NO_VIDEO: 'container/HomePage/NO_VIDEO',
    CLEAR_QUESTION: 'container/HomePage/CLEAR_QUESTION',
    LISTENING_UPDATE: 'container/HomePage/LISTENING_UPDATE',
  };

  const questionIdle = payload => ({
    type: ActionTypes.QUESTION_IDLE,
    payload
  });
  
  const questionAsked = (question, params) => ({
    type: ActionTypes.QUESTION_ASKED,
    payload: {
      question,
      params
    },
  });

  const questionChanged = payload => ({
    type: ActionTypes.QUESTION_CHANGE,
    payload
  });

  const listeningUpdate = payload => ({
    type: ActionTypes.LISTENING_UPDATE,
    payload
  });

  const updateQuestion = payload => ({
    type: ActionTypes.UPDATE_QUESTION,
    payload
  });
   
  const fetchAgentVideosSuccess = response => ({
    type: ActionTypes.FETCH_AGENT_VIDEOS_SUCCESS,
    payload: {
      response,
    },
  });

  const videoNotFound = () => ({
    type: ActionTypes.NO_VIDEO
  })
  
  const fetchAgentVideosFailed = () => ({
    type: ActionTypes.FETCH_AGENT_VIDEOS_FAILED,
  });
  
  const fetchAgentVideos = contentId => ({
    type: ActionTypes.FETCH_AGENT_VIDEOS,
    payload: {
      contentId,
    },
  });
  
  const clearQuestion = () => ({
    type: ActionTypes.CLEAR_QUESTION,
  });
  
  export default {
    questionAsked,
    fetchAgentVideosSuccess,
    fetchAgentVideosFailed,
    fetchAgentVideos,
    clearQuestion,
    videoNotFound,
    listeningUpdate,
    updateQuestion,
    questionChanged,
    questionIdle
  };
  