import Axios from "axios";
import { apiHeader } from "./apiServiceConfig";
import { API_URL } from "../constant/actionTypes";

export const autoGenerateScript = (data) => {
  return Axios.post(`${API_URL}interaction/script/save`,data ,apiHeader )
    .then(response => response)
    .catch((error) => console.trace(error));
}

export const getInteractionList = (data) => {
    return Axios.post(`${API_URL}interaction/content/lists`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const updateInteractionData = (data) => {
    return Axios.post(`${API_URL}interaction/video/description/update`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const updateContentData = (data) => {
    return Axios.post(`${API_URL}interaction/content/update`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

export const createInteractionData = (data) => {
  return Axios.post(`${API_URL}interaction/video/description/add`,data ,apiHeader )
                  .then(response => response)
                  .catch((error) => console.trace(error));
}

export const createContentData = (data) => {
    return Axios.post(`${API_URL}interaction/content/add`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

export const fetchSingleInteractionData = (data) => {
    return Axios.post(`${API_URL}interaction/content/user/list`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const fetchContentData = (data) => {
    return Axios.post(`${API_URL}interaction/content/list`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getUserInteractionList = (data) => {
    return Axios.post(`${API_URL}interaction/user/details`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const createEnquiryFields = (data) => {
    return Axios.post(`${API_URL}interaction/enquiry/field`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordAnsweredQuestions = (data) => {
    return Axios.post(`${API_URL}analytics/record/answered-question`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordUnavailableQuestions = (data) => {
    return Axios.post(`${API_URL}analytics/record/unavailable-question`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordContactAttempt = (data) => {
    return Axios.post(`${API_URL}analytics/record/contact-attempt`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordVideoBotWatchedDuration = (data) => {
    return Axios.post(`${API_URL}analytics/record/videobot-watched-duration`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordEnquiryFormSubmission = (data) => {
    return Axios.post(`${API_URL}analytics/record/enquiry-form-attempt`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const recordVideobotViews = (data) => {
    return Axios.post(`${API_URL}analytics/record/videobot-views`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedAnsweredQuestions = (data) => {
    return Axios.post(`${API_URL}analytics/get/answered-question-access-wise`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedUnavailableQuestions = (data) => {
    return Axios.post(`${API_URL}analytics/get/unavailable-questions`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedContactAttempt = (data) => {
    return Axios.post(`${API_URL}analytics/get/contact-attempts`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedVideoBotWatchedDuration = (data) => {
    return Axios.post(`${API_URL}analytics/get/videobot-watched-duration`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedVideobotViews = (data) => {
    return Axios.post(`${API_URL}analytics/get/videobot-views-count`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedEnquiryFormSubmission = (data) => {
    return Axios.post(`${API_URL}analytics/get/enquiry-form-attempt`,data ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  export const getRecordedTopThreeQuestion = (data) => {
    return Axios.post(`${API_URL}analytics/get/top-n-questions`,{...data, top: 3} ,apiHeader )
                    .then(response => response)
                    .catch((error) => console.trace(error));
  }

  // export const getRecordedTopThreeQuestion = (data) => {
  //   return Axios.post(`${API_URL}analytics/get/top-three-questions`,data ,apiHeader )
  //                   .then(response => response)
  //                   .catch((error) => console.trace(error));
  // }