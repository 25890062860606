import React, {useState} from 'react'
import ReactTable from 'react-table';
import Datatable from '../common/datatable'
import Pagination from 'react-js-pagination'

function DetailTable() {

    const [data, setData] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [totalData, setTotalData] = useState(0);

    const details = [
        {
          Header: <b>Bot Name</b>,
          accessor: 'botName',
          style: {
            textAlign: 'center'
          }
        },
        {
          Header: <b>Activity Count</b>,
          accessor: 'count',
          style: {
            textAlign: 'center'
          }
        },
        {
          Header: <b>Lead Submit Count</b>,
          accessor: 'formCount',
          style: {
            textAlign: 'center'
          }
        }
      ]

      const handlePageChange = data => {
        if(data !== activePage){
            setActivePage(data)
          }
        }

    return (
        <div>
            <Datatable
                myData={data}
                columns={details}
                pageSize={10}
                myClass='ReactTable'
              />
              <Pagination
                  activePage={parseInt(activePage)}
                  itemsCountPerPage={10}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
              />
        </div>
    )
}

export default DetailTable
