import { put, takeLatest, all, call, take } from 'redux-saga/effects';
import videoAction, { ActionTypes } from '../actions/video';
import { captureActivity } from '../actions/activity';
import { ACTIVITY_TRIGGER } from '../constant/actionType/activity';
import { fetchVideoBot } from '../service/videoBotApi';
import { eventTrigger } from '../service/activity';
import { sessionActivity } from "../service/apiServiceConfig";
import { setCookie, getCookie } from '../service/commonFunction';

export function* handleFetchAgentVideos(action) {
  try {
    let params  = {...action.payload};
    
      if (params){
        const response = yield call(fetchVideoBot, params);
        if (response.status == 200) {
          if (response.data.status == 1){

            const payload = {
              data: response.data.data,
              message: 'success'
            }
            yield put(videoAction.fetchAgentVideosSuccess(payload))
            
          }else{
            const payload = {
              message: 'Could not fetch interaction list'
            }
            yield put(videoAction.fetchAgentVideosFailed(payload))
          }
        }else{
          const payload = {
            message: 'Could not fetch interaction list'
          }
          yield put(videoAction.fetchAgentVideosFailed(payload))
        }
      }else{
        const payload = {
          message: 'Could not fetch interaction list'
        }
        yield put(videoAction.fetchAgentVideosFailed(payload))
      }
  } catch (error) {
    const payload = {
      message: 'Could not fetch interaction list'
    }
    yield put(videoAction.fetchAgentVideosFailed(payload))
    console.trace(error);
  }
}

export function* handleSessionStart(action){
  let actionPayload = {payload: {...sessionActivity} }
  getCookie('sessionstart')
  setCookie('sessionstart', 1 ,60)
  yield call(eventTrigger, actionPayload);
}

export function* handleVideoChange(action) {
  try {
    let params  = {...action.payload};
      if (params){
        // yield take(ACTIVITY_TRIGGER)
        yield put(videoAction.questionChanged(params))
      }
  } catch (error) {
    console.trace(error);
  }
}

export function* handleIdleChange(action) {
  try {
    let params  = {...action.payload};
      if (params){
        yield put(captureActivity(params))
        yield put(videoAction.clearQuestion())
        
      }
  } catch (error) {
    console.trace(error);
  }
}

export default function* actionWatcher() {
  yield all([
     takeLatest(ActionTypes.FETCH_AGENT_VIDEOS, handleFetchAgentVideos),
     takeLatest(ActionTypes.QUESTION_ASKED, handleVideoChange),
     takeLatest(ActionTypes.QUESTION_IDLE, handleIdleChange),
     takeLatest(ActionTypes.FETCH_AGENT_VIDEOS_SUCCESS, handleSessionStart)
  ]);
  }