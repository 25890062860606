import {
  CREATE_INTERACTION,
  CREATE_INTERACTION_LIST,
  FETCH_INTERACTION,
  FETCH_INTERACTION_LIST,
  UPDATE_INTERACTION,
  UPDATE_INTERACTION_LIST,
  FETCH_SINGLE_INTERACTION,
  FETCH_USER_INTERACTION,
  FETCH_USER_INTERACTION_LIST,
  INTERACTION_ERROR,
  CREATE_CONTENT,
  CREATE_CONTENT_LIST,
  UPDATE_CONTENT,
  UPDATE_CONTENT_LIST,
  AUTO_GENERATE_CONTENT,
  CREATE_ENQUIRY_FIELDS,
  RECORD_ANSWERED_QUESTIONS,
  RECORD_UNAVAILABLE_QUESTIONS,
  RECORD_CONTACT_ATTEMPT,
  RECORD_VIDEOBOT_WATCHED_DURATION,
  RECORD_ENQUIRY_FORM_SUBMISSION,
  RECORD_VIDEOBOT_VIEWS,
  GET_RECORDED_ANSWERED_QUESTIONS,
  GET_RECORDED_UNAVAILABLE_QUESTIONS,
  GET_RECORDED_CONTACT_ATTEMPT,
  GET_RECORDED_VIDEOBOT_WATCHED_DURATION,
  GET_RECORDED_VIDEOBOT_VIEW_COUNT,
  GET_RECORDED_ENQUIRY_FORM_SUBMISSION,
} from '../constant/actionType/interaction';

export const createEnquiryFields = payload => {
  return {
    type: CREATE_ENQUIRY_FIELDS,
    payload
  }
};

export const autoGenerateList = payload => {
  return {
    type: AUTO_GENERATE_CONTENT,
    payload
  }
};

export const createInteraction = payload => {
  return {
    type: CREATE_INTERACTION,
    payload
  }
};

export const createInteractionList = payload => {
  return {
    type: CREATE_INTERACTION_LIST,
    payload
  }
};

export const createContent = payload => {
  return {
    type: CREATE_CONTENT,
    payload
  }
};

export const createContentList = payload => {
  return {
    type: CREATE_CONTENT_LIST,
    payload
  }
};

export const fetchSingleInteraction = payload => {
  return {
    type: FETCH_SINGLE_INTERACTION,
    payload
  }
};

export const fetchUserInteraction = payload => {
  return {
    type: FETCH_USER_INTERACTION,
    payload
  }
};

export const fetchUserInteractionList = payload => {
  return {
    type: FETCH_USER_INTERACTION_LIST,
    payload
  }
};

export const fetchInteraction = () => {
  return {
    type: FETCH_INTERACTION
  }
};

export const fetchInteractionList = () => {
  return {
    type: FETCH_INTERACTION_LIST
  }
};

export const updateInteraction = payload => {
  return {
    type: UPDATE_INTERACTION,
    payload
  }
};

export const updateInteractionList = payload => {
  return {
    type: UPDATE_INTERACTION_LIST,
    payload
  }
};

export const updateContent = payload => {
  return {
    type: UPDATE_CONTENT,
    payload
  }
};

export const updateContentList = payload => {
  return {
    type: UPDATE_CONTENT_LIST,
    payload
  }
};

export const errorInteraction = payload => {
  return {
    type: INTERACTION_ERROR,
    payload
  }
};

export const recordAnsweredQuestions = payload => {
  return {
    type: RECORD_ANSWERED_QUESTIONS,
    payload
  }
};

export const recordUnAvailableQuestions = payload => {
  return {
    type: RECORD_UNAVAILABLE_QUESTIONS,
    payload
  }
};

export const recordContactAttempt = payload => {
  return {
    type: RECORD_CONTACT_ATTEMPT,
    payload
  }
};

export const recordVideoBotWatchedDuration = payload => {
  return {
    type: RECORD_VIDEOBOT_WATCHED_DURATION,
    payload
  }
};

export const recordEnquiryFormSubmission = payload => {
  return {
    type: RECORD_ENQUIRY_FORM_SUBMISSION,
    payload
  }
};

export const recordVideoBotViews = payload => {
  return {
    type: RECORD_VIDEOBOT_VIEWS,
    payload
  }
};
export const getRecordedAnsweredQuestions = payload => {
  return {
    type: GET_RECORDED_ANSWERED_QUESTIONS,
    payload
  }
};
export const getRecordedUnavailableQuestions = payload => {
  return {
    type: GET_RECORDED_UNAVAILABLE_QUESTIONS,
    payload
  }
};
export const getRecordedContactAttemptCount = payload => {
  return {
    type: GET_RECORDED_CONTACT_ATTEMPT,
    payload
  }
};
export const getRecordedVideobotWatchedDuration = payload => {
  return {
    type: GET_RECORDED_VIDEOBOT_WATCHED_DURATION,
    payload
  }
};
export const getRecordedVideobotViewsCount = payload => {
  return {
    type: GET_RECORDED_VIDEOBOT_VIEW_COUNT,
    payload
  }
};

export const getRecordedEnquiryFormSubmissionCount = payload => {
  return {
    type: GET_RECORDED_ENQUIRY_FORM_SUBMISSION,
    payload
  }
};