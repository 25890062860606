import Axios from "axios";
import { apiHeader, sessionActivity } from "./apiServiceConfig";
import { API_URL } from "../constant/actionTypes";
import { select, call } from 'redux-saga/effects';
import { getCookie } from "./commonFunction";

export const botdata = (state) => state.VideoReducer

export function* eventTrigger(params){
  if (process.env.NODE_ENV !== `development`) {

    const {botId, sessionId, activeAgentVideos, language, questionAsked, questionPlayed, responseAnswer , responseVideoId, videoPath, action, performedOn, type} = yield select(botdata)
    const {name, organisationName} = activeAgentVideos;
    let payload = {...params.payload, botId: botId, sessionId: sessionId, botOrganisationName: organisationName, botName: name, language: language}
    
    if (payload.eventName == "questionAsked"){
      if (!responseVideoId || responseVideoId=="") return false;
      payload = {action, performedOn, ...payload, questionPlayed, questionAsked, responseAnswer, responseVideoId, videoPath}
    }
    
    return Axios.post(`${API_URL}activity/trigger-event`,payload ,apiHeader )
      .then(response => response)
      .catch((error) => console.trace(error));
  }

}

export const getAllActivity = (params) => {
  let payload;
  if (params){
    payload = {...params}
  }else{
    payload = {
      perPage:100,
      page: 1
    }
  }

  return Axios.post(`${API_URL}activity/event/list`,payload ,apiHeader )
    .then(response => response)
    .catch((error) => console.trace(error));
}

export const getBotActivity = (params) => {
  let payload;
  // if (params){
  //   if (params.page)
  //     payload = {...params}
  // }

  // if (!payload){
  //   payload = {
  //     ...params.payload,
  //     perPage:30,
  //     page: 1
  //   }
  // }

  payload = {
    perPage:30,
    page: 1,
    ...params.payload,
  }

  return Axios.post(`${API_URL}activity/bot-event/list`,payload ,apiHeader )
    .then(response => response)
    .catch((error) => console.trace(error));
}