import { take, call, put, cancel, takeLatest, fork, all } from 'redux-saga/effects';
import { createInteraction, createInteractionList, fetchInteraction, fetchSingleInteraction,
fetchInteractionList, updateInteractionList, updateContentList, errorInteraction, createContentList,
 fetchUserInteractionList} from '../actions/interaction';
import { CREATE_INTERACTION, UPDATE_CONTENT, AUTO_GENERATE_CONTENT, FETCH_INTERACTION, CREATE_CONTENT,
   FETCH_SINGLE_INTERACTION, UPDATE_INTERACTION, FETCH_USER_INTERACTION, CREATE_ENQUIRY_FIELDS, RECORD_ANSWERED_QUESTIONS,
   RECORD_UNAVAILABLE_QUESTIONS, RECORD_CONTACT_ATTEMPT, RECORD_VIDEOBOT_WATCHED_DURATION, RECORD_ENQUIRY_FORM_SUBMISSION, RECORD_VIDEOBOT_VIEWS, GET_RECORDED_ANSWERED_QUESTIONS,
   GET_RECORDED_UNAVAILABLE_QUESTIONS,
   GET_RECORDED_CONTACT_ATTEMPT,
   GET_RECORDED_VIDEOBOT_WATCHED_DURATION,
   GET_RECORDED_VIDEOBOT_VIEW_COUNT,
   GET_RECORDED_ENQUIRY_FORM_SUBMISSION  } from '../constant/actionType/interaction';
import * as integrationApi from '../service/interaction';

function* createInteractionSaga(action) {
    try {
      let params  = {...action.payload};
      
        if (params){
          const response = yield call(integrationApi.createInteractionData
            , params);

          if (response.status == 200) {
            if (response.data.status == 1){
              const payload = {
                id: params.contentId,
                data: [{...response.data.data}],
                message: 'success'
              }
              yield put(createInteractionList(payload))
            }else{
              const payload = {
                message: 'Could not create interaction list'
              }
              yield put(errorInteraction(payload))
            }
          }else{
            const payload = {
              message: 'Could not create interaction list'
            }
            yield put(errorInteraction(payload))
          }
        }else{
          const payload = {
            message: 'Could not create interaction list'
          }
          yield put(errorInteraction(payload))
        }
    } catch (error) {
      const payload = {
        message: 'Could not create interaction list'
      }
      yield put(errorInteraction(payload))
      console.trace(error);
    }
}

function* updateInteractionSaga(action) {
  try {
    let params = action.payload
    let payload;
    const response = yield call(integrationApi.updateInteractionData, params);
    if (response.status == 200) {

      if (response.data.status == 1){
        payload = {
          id: action.payload.interactionId,
          data: response.data.data,
          message: 'success'
        }
        yield put(updateInteractionList(payload))
      }else{
        payload = {
          message: 'Could not update interaction list'
        }
        yield put(errorInteraction(payload))
      }
    }else{
       payload = {
        message: 'Could not update interaction list'
      }
      yield put(errorInteraction(payload))
    }
  } catch (error) {
    const payload = {
      message: 'Could not update interaction list'
    }
    yield put(errorInteraction(payload))
    // console.trace(error);
  }
}

function* fetchInteractionSaga(action) {
  try {
    let params;
    if (action.payload){
      params = action.payload
    }else{
      params = {
        page: 1,
        perPage: 30
      }
    }

    const response = yield call(integrationApi.getInteractionList, params);
    if (response.status == 200) {
      const payload = {
        data: response.data.data,
        message: 'success'
      }
      yield put(fetchInteractionList(payload))
    }else{
      const payload = {
        message: 'Could not load interaction list'
      }
      yield put(errorInteraction(payload))
    }
  } catch (error) {
    console.trace(error);
  }
}

function* fetchSingleInteractionSaga(action) {
  try {
    let params;
    if (action.payload){
      params = action.payload
    }else{
      params = {
        page: 1,
        perPage: 30
      }
    }

    const response = yield call(integrationApi.getInteractionList, params);
    if (response.status == 200) {
      const payload = {
        data: response.data.data,
        message: 'success'
      }
      yield put(fetchInteraction(payload))
    }else{
      const payload = {
        message: 'Could not load interaction list'
      }
      yield put(errorInteraction(payload))
    }
  } catch (error) {
    console.trace(error);
  }
}

function* fetchUserInteractionSaga(action) {
  try {
    let params;
      if (localStorage.getItem('alacagid') == 'admin'){
        params = action.payload;
      }else if (localStorage.getItem('alacagid') == 'user'){
        params = {
          userId : localStorage.getItem('alacaid')
        }
      }
      if (params){
        const response = yield call(integrationApi.getUserInteractionList, params);
        if (response.status == 200) {
          const payload = {
            data: response.data.data,
            message: 'success'
          }
          yield put(fetchUserInteractionList(payload))
        }else{
          const payload = {
            message: 'Could not load interaction list'
          }
          yield put(errorInteraction(payload))
        }
      }else{
        const payload = {
          message: 'Could not load interaction list'
        }
        yield put(errorInteraction(payload))
      }
  } catch (error) {
    console.trace(error);
  }
}

function* updateContentSaga(action) {
  try {
    let params  = {...action.payload};
    
      if (params){
        const response = yield call(integrationApi.updateContentData, params);
        if (response.data.status == 1) {
          const payload = {
            data: response.data.data,
            message: 'success'
          }
          yield put(updateContentList(payload))
        }else{
          const payload = {
            message: 'Could not update Content'
          }
          yield put(errorInteraction(payload))
        }
      }else{
        const payload = {
          message: 'Could not load interaction list'
        }
        yield put(errorInteraction(payload))
      }
  } catch (error) {
    console.trace(error);
  }
}

function* createContentSaga(action) {
  try {
    let params  = {...action.payload};

    if (localStorage.getItem('alacagid') == "user"){
      params = {...params, userId: localStorage.getItem('alacaid')}
    }

    if (params){
      if(!params["name"]){
        params["name"] = params.expertFrom;
      }

      if(!params["floatingText"]){
        params["floatingText"] = "";
      }
      
      const response = yield call(integrationApi.createContentData, params);

      if (response) {
        if (response.status == 200) {
        if (response.data.status == 1) {
          const payload = {
            data: response.data.data,
            message: 'success'
          }
          yield put(createContentList(payload))
        }else{
          const payload = {
            message: response.data.message ? response.data.message : 'Could not create Interaction Content'
          }
          yield put(errorInteraction(payload))
        }
      }else{
        const payload = {
          message: 'Could not create Interaction Content'
        }
        yield put(errorInteraction(payload))
      }
    }else{
      const payload = {
        message: 'Could not create Interaction Content'
      }
      yield put(errorInteraction(payload))
    }
    }else{
      const payload = {
        message: 'Could not create Interaction Content'
      }
      yield put(errorInteraction(payload))
    }
  } catch (error) {
    console.trace(error);
    const payload = {
      message: 'Could not create Interaction Content'
    }
    yield put(errorInteraction(payload))
  }
}

function* autoGenerateContent(action) {
  try {
    let params  = {...action.payload};
    
      if (params){
        const response = yield call(integrationApi.autoGenerateScript, params);

        if (response.status == 200) {
          if (response.data.status == 1){
            const payload = {
              id: params.contentId,
              data: response.data.data,
              message: 'success'
            }
            yield put(createInteractionList(payload))
          }else{
            const payload = {
              message: 'Could not load interaction list'
            }
            yield put(errorInteraction(payload))
          }
        }else{
          const payload = {
            message: 'Could not load interaction list'
          }
          yield put(errorInteraction(payload))
        }
      }else{
        const payload = {
          message: 'Could not load interaction list'
        }
        yield put(errorInteraction(payload))
      }
  } catch (error) {
    console.trace(error);
  }
}

function* createEnquiryFieldsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.createEnquiryFields, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* recordAnsweredQuestionsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordAnsweredQuestions, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* recordUnavailableQuestionsSaga(action) {
  try {
    console.log('recordAskedQuesSaga', JSON.stringify(action))
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordUnavailableQuestions, params);
      }
  } catch (error) {
    console.trace(error);
  }
}
function* recordContactAttemptSaga(action) {
  try {
    console.log('recordAskedQuesSaga', JSON.stringify(action))
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordContactAttempt, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* recordVideoBotWatchedDurationSaga(action) {
  try {
    console.log('recordAskedQuesSaga', JSON.stringify(action))
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordVideoBotWatchedDuration, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* recordEnquiryFormSubmissionSaga(action) {
  try {
    console.log('recordAskedQuesSaga', JSON.stringify(action))
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordEnquiryFormSubmission, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* recordVideobotViewsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.recordVideobotViews, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedAnsweredQuestionsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedAnsweredQuestions, params);
        console.log("getRecordedAnsweredQuestionsSaga", JSON.stringify(response.data))
        return response.data;
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedUnavailableQuestionsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedUnavailableQuestions, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedContactAttemptSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedContactAttempt, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedVideoBotWatchedDurationSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedVideoBotWatchedDuration, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedVideobotViewsSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedVideobotViews, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

function* getRecordedEnquiryFormSubmissionSaga(action) {
  try {
    let params  = {...action.payload};
      if (params){
        const response = yield call(integrationApi.getRecordedEnquiryFormSubmission, params);
      }
  } catch (error) {
    console.trace(error);
  }
}

export default function* actionWatcher() {
  yield all([
    takeLatest(UPDATE_INTERACTION, updateInteractionSaga),
    takeLatest(CREATE_INTERACTION, createInteractionSaga),
    takeLatest(FETCH_INTERACTION, fetchInteractionSaga),
    takeLatest(FETCH_SINGLE_INTERACTION, fetchSingleInteractionSaga),
    takeLatest(FETCH_USER_INTERACTION, fetchUserInteractionSaga),
    takeLatest(UPDATE_CONTENT, updateContentSaga),
    takeLatest(CREATE_CONTENT, createContentSaga),
    takeLatest(CREATE_ENQUIRY_FIELDS, createEnquiryFieldsSaga),
    takeLatest(AUTO_GENERATE_CONTENT, autoGenerateContent),
    takeLatest(RECORD_ANSWERED_QUESTIONS, recordAnsweredQuestionsSaga),
    takeLatest(RECORD_UNAVAILABLE_QUESTIONS, recordUnavailableQuestionsSaga),
    takeLatest(RECORD_CONTACT_ATTEMPT, recordContactAttemptSaga),
    takeLatest(RECORD_VIDEOBOT_WATCHED_DURATION, recordVideoBotWatchedDurationSaga),
    takeLatest(RECORD_ENQUIRY_FORM_SUBMISSION, recordEnquiryFormSubmissionSaga),
    takeLatest(RECORD_VIDEOBOT_VIEWS, recordVideobotViewsSaga),
    takeLatest(GET_RECORDED_ANSWERED_QUESTIONS, getRecordedAnsweredQuestionsSaga),
    takeLatest(GET_RECORDED_UNAVAILABLE_QUESTIONS, getRecordedUnavailableQuestionsSaga),
    takeLatest(GET_RECORDED_CONTACT_ATTEMPT, getRecordedContactAttemptSaga),
    takeLatest(GET_RECORDED_VIDEOBOT_WATCHED_DURATION, getRecordedVideoBotWatchedDurationSaga),
    takeLatest(GET_RECORDED_VIDEOBOT_VIEW_COUNT, getRecordedVideobotViewsSaga),
    takeLatest(GET_RECORDED_ENQUIRY_FORM_SUBMISSION, getRecordedEnquiryFormSubmissionSaga)
  ])
}
