import React, { useState, useEffect } from 'react'
import Counts from '../../components/counts/index'
import '../../assets/scss/analytics.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as integrationApi from '../../service/interaction';
import { getRecordedAnsweredQuestions, getRecordedUnavailableQuestions, getRecordedContactAttemptCount, getRecordedVideobotViewsCount, getRecordedVideobotWatchedDuration, getRecordedEnquiryFormSubmissionCount } from '../../actions/interaction';
import moment from 'moment';

function Index(props) {

    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [analyticsData, setAnalyticsData] = useState({})
    const [analyticsTotalData, setAnalyticsTotalData] = useState({})

    useEffect(() => {
        setLoading(true);
        callToGetAnalyticsData();
    }, [])

    const callToGetAnalyticsData = async () => {
        const reqPayload = { interactionContentId: props.location.state.contentId, from : moment().format('YYYY-MM-DD')+' 00:00:00' }
        const totalReqPayload = { interactionContentId: props.location.state.contentId }
        Promise.all([
            // current date data
            integrationApi.getRecordedContactAttempt(reqPayload),
            integrationApi.getRecordedEnquiryFormSubmission(reqPayload),
            integrationApi.getRecordedVideobotViews(reqPayload),
            integrationApi.getRecordedTopThreeQuestion(reqPayload),
            integrationApi.getRecordedAnsweredQuestions(reqPayload),
            integrationApi.getRecordedVideoBotWatchedDuration(reqPayload),

            //total data
            integrationApi.getRecordedContactAttempt(totalReqPayload),
            integrationApi.getRecordedEnquiryFormSubmission(totalReqPayload),
            integrationApi.getRecordedVideobotViews(totalReqPayload),
            integrationApi.getRecordedTopThreeQuestion(totalReqPayload),
            integrationApi.getRecordedAnsweredQuestions((totalReqPayload)),
            integrationApi.getRecordedVideoBotWatchedDuration(totalReqPayload),
        ]).then(function (responses) {
            return responses;
        }).then(async function (data) {
            // console.log("response data===="+JSON.stringify(data))
            const updatedAnalyticsRes = data.map(response => { 
                if(response && response.data)
                     return { ...response.data } 
                else return {status:0, data:{}}
           })

            const contactAttemptRes = handleAnalyticApiRes(updatedAnalyticsRes[0], 'contactAttempt', false);
            const enquiryFormCountRes = handleAnalyticApiRes(updatedAnalyticsRes[1], 'enquiryFormCount', false);
            const videobotViewsRes = handleAnalyticApiRes(updatedAnalyticsRes[2], 'videobotViews', false);
            const topThreeQueRes = handleAnalyticApiRes(updatedAnalyticsRes[3], 'topThreeQue', false);
            const questionAskedRes = handleAnalyticApiRes(updatedAnalyticsRes[4], 'questionAsked', false);
            const videoDurationRes = handleAnalyticApiRes(updatedAnalyticsRes[5], 'videoDuration', false);


            const contactAttemptTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[6], 'contactAttempt', true);
            const enquiryFormCountTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[7], 'enquiryFormCount', true);
            const videobotViewsTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[8], 'videobotViews', true);
            const topThreeQueTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[9], 'topThreeQue', true);
            const questionAskedTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[10], 'questionAsked', true);
            const videoDurationTotalRes = handleAnalyticApiRes(updatedAnalyticsRes[11], 'videoDuration', true);

            setAnalyticsData({
                ...contactAttemptRes,
                ...enquiryFormCountRes,
                ...videobotViewsRes,
                ...topThreeQueRes,
                ...questionAskedRes,
                ...videoDurationRes
            })

            setAnalyticsTotalData({
                ...contactAttemptTotalRes,
                ...enquiryFormCountTotalRes,
                ...videobotViewsTotalRes,
                ...topThreeQueTotalRes,
                ...questionAskedTotalRes,
                ...videoDurationTotalRes
            })

        }).catch(function (error) {
            // if there's an error, log it
            setLoading(false);
            console.log(error);
        });
    }

    const handleDateChange = async (data, type) => {
        const reqPayload = { ...data, interactionContentId: props.location.state.contentId }
        switch (type) {
            case 'contactAttempt': {
                const res = await integrationApi.getRecordedContactAttempt(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;
            }
            case 'enquiryFormCount': {
                const res = await integrationApi.getRecordedEnquiryFormSubmission(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;

            }
            case 'videobotViews': {
                const res = await integrationApi.getRecordedVideobotViews(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;
            }
            case 'topThreeQue': {
                const res = await integrationApi.getRecordedTopThreeQuestion(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;
            }

            case 'questionAsked': {
                const res = await integrationApi.getRecordedAnsweredQuestions(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;
            }

            case 'videoDuration': {
                const res = await integrationApi.getRecordedVideoBotWatchedDuration(reqPayload);
                handleAnalyticApiRes(res && res.data ? res.data : {}, type, false);
                break;
            }

            default:
        }
    }

    const handleAnalyticApiRes = (res, type, isTotalRes) => {
        let response = {};

        if ( res.status === 1 && res.data) {
            if (type === 'contactAttempt') {
                const contactArr = Object.keys(res.data).map(key => { return { name: key, count: res.data[key] } })
                response = { ...response, contactAttempt: contactArr };
            }

            if (type === 'enquiryFormCount') {
                response = { ...response, enquiryFormCount: res.data };
            }

            if (type === 'videobotViews') {
                response = { ...response, videobotViews: res.data };
            }

            if (type === 'topThreeQue') {
                const topthreeQueArr = res.data.map(que => {
                    return { count: que.count, name: que.questionDetails.question }
                })
                response = { ...response, topThreeQue: topthreeQueArr };
            }

            if (type === 'questionAsked') {
                const questionAskedArr = Object.keys(res.data).map(key => { return { name: key, count: res.data[key] } })

                if(isTotalRes === false){
                    questionAskedArr.push({ name: 'Total Asked', count: calculateTotalCount(res.data.click, res.data.mic) })
                    response = { ...response, questionAsked: questionAskedArr };
                } else{
                    response = { ...response, questionAsked: {count: calculateTotalCount(res.data.click, res.data.mic)} };
                }
                
            }

            if (type === 'videoDuration') {
                response = { ...response, videoDuration: { count: msToTime(res.data.duration) } };
            }

            if(isTotalRes === false)
                setAnalyticsData({ ...analyticsData, ...response });
            setLoading(false);
            return response;
        } else {
            response = { ...analyticsData };
            delete response[type];
            if(isTotalRes === false)
                setAnalyticsData({ ...response });
        }

    }

    const msToTime = (milliseconds) => {
        //Get hours from milliseconds
        var hours = milliseconds / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        let time ='';
        if (h == '00' && m == '00')
            time = s + ' secs';
        else if (h == '00')
            time = m + ' mins ' + s + ' secs';
        else
            time = h + ' hrs ' + m + ' mins ' + s + ' secs ';

        return time;

    }


    const calculateTotalCount = (clickCount, micCount) => {
        if (clickCount === undefined && micCount === undefined)
            return 0
        else if (clickCount === undefined)
            return micCount
        else if (micCount === undefined)
            return clickCount
        else
            return clickCount + micCount
    }

    return (
        <div className="analytics-index">
            <Counts title='Enquiry Contact Attempt - Unclear' counts={count} isLoading={loading} redirectionLink={`/form-responses/${props.location.state.contentId}`} data={analyticsData.contactAttempt ? analyticsData.contactAttempt : []} singleEntry={false} type={"contactAttempt"} onDateChange={handleDateChange} total={analyticsTotalData.contactAttempt ? analyticsTotalData.contactAttempt : 0}/>
            <Counts title='Enquiry form Submitted' counts={count} isLoading={loading} redirectionLink='' data={analyticsData.enquiryFormCount ? analyticsData.enquiryFormCount : 0} singleEntry={true} type={"enquiryFormCount"} onDateChange={handleDateChange} total={analyticsTotalData.enquiryFormCount ? analyticsTotalData.enquiryFormCount : 0}/>
            <Counts title='Videobot Views' counts={count} isLoading={loading} redirectionLink='' data={analyticsData.videobotViews ? analyticsData.videobotViews : 0} singleEntry={true} type={"videobotViews"} onDateChange={handleDateChange} total={analyticsTotalData.videobotViews ? analyticsTotalData.videobotViews : 0}/>
            <Counts title='Top Three Questions' counts={count} isLoading={loading} redirectionLink='' data={analyticsData.topThreeQue ? analyticsData.topThreeQue : []} singleEntry={false} type={"topThreeQue"} onDateChange={handleDateChange} total={analyticsTotalData.topThreeQue ? analyticsTotalData.topThreeQue : 0}/>
            <Counts title='Questions Asked' counts={count} isLoading={loading} redirectionLink='' data={analyticsData.questionAsked ? analyticsData.questionAsked : []} singleEntry={false} type={"questionAsked"} onDateChange={handleDateChange} total={analyticsTotalData.questionAsked ? analyticsTotalData.questionAsked : 0}/>
            <Counts title='Video Duration' counts={count} isLoading={loading} redirectionLink='' data={analyticsData.videoDuration ? analyticsData.videoDuration : 0} singleEntry={true} type={"videoDuration"} onDateChange={handleDateChange} total={analyticsTotalData.videoDuration ? analyticsTotalData.videoDuration : 0}/>

        </div>
    )
}

//export default Index

const mapStateToProps = state => ({
    interaction: state.interaction,
})

const mapDispatchToProps = {
    getRecordedAnsweredQuestions,
    getRecordedUnavailableQuestions,
    getRecordedContactAttemptCount,
    getRecordedVideobotViewsCount,
    getRecordedVideobotWatchedDuration,
    getRecordedEnquiryFormSubmissionCount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));