/*
 *
 * video Reducer
 *
 */
 
import { ActionTypes } from "../actions/video";

/* eslint-disable default-case, no-param-reassign */

const initial_state = {
  loading: false,
  activeQuestion: '',
  activeAgentVideos: [],
  message: '',
  transcript : '',
  listening: false,
  language: "en-US",
  botId: "",
  sessionId: "",
  videoChanged : false,
  questionPlayed: "",
  questionAsked:"",
  responseAnswer:"",
  responseVideoId:"",
  videoDuration: "",
  videoWatchDuration: "",
  action: "",
  performedOn:"",
  type:""
};

export default (state = initial_state, action) => {
    switch (action.type) {
        // case ActionTypes.QUESTION_ASKED: {
        //   const { question, params } = action.payload;

        //   return {
        //     ...state,
        //     loading: false,
        //     activeQuestion: question || "",
        //     message: "",
        //     transcript: params.transcript,
        //     listening: params.listening
        //   }; 
        // }

        case ActionTypes.QUESTION_CHANGE: {
          const { question, params } = action.payload;

          let data = {
            ...state,
            loading: false,
            message: "",
            transcript: params.transcript,
            listening: params.listening,
            videoChanged: false,
          }

          if (question != "")
            data = {...data,
              activeQuestion: question || "",
              questionAsked: question || "",
              action: params.action,
              performedOn: params.performedOn
            }

          return {...data}

          // return {
          //   ...state,
          //   loading: false,
          //   activeQuestion: question || "",
          //   questionAsked: question || "",
          //   message: "",
          //   transcript: params.transcript,
          //   listening: params.listening,
          //   videoChanged: false,
          //   action: params.action,
          //   performedOn: params.performedOn
          // }; 
        }

        case ActionTypes.QUESTION_ASKED: {
          const { question, params } = action.payload;

          return {
            ...state,
            videoChanged: true
          }; 
        }

        case ActionTypes.UPDATE_QUESTION: {
          return {
            ...state,
            questionPlayed: action.payload.questionResponse,
            videoPath: action.payload.videoPath,
            responseAnswer: action.payload.answer,
            responseVideoId: action.payload.responseVideoId,
            questionAsked: action.payload.questionAsked,
            type: action.payload.type,
          }; 
        }

        case ActionTypes.LISTENING_UPDATE: {
          return {
            ...state,
            transcript : action.payload.transcript,
            listening: action.payload.listening
          };
        }

        case ActionTypes.FETCH_AGENT_VIDEOS: {
          return {
            ...state,
            loading: true,
          };
        }

        case ActionTypes.FETCH_AGENT_VIDEOS_SUCCESS: {
          const { response } = action.payload;
          return {
            ...state,
            loading: false,
            activeAgentVideos: response.data,
            botId: response.data.id,
            sessionId: response.data.sessionId,
            message: response.message
          };
        }
        case ActionTypes.CLEAR_QUESTION: {
          return {
            ...state,
            loading: false,
            activeQuestion: "",
            message: "",
            transcript: "",
            questionPlayed: "",
            questionAsked:"",
            responseAnswer:"",
            responseVideoId:"",
            videoDuration: "",
            videoWatchDuration: "",
            videoPath: "",
            type: ""
          };
        }
        default:
          return state;
      }
}
