import {
  CREATE_USER,
  UPDATE_USER,
  FETCH_USER_LIST,
  FETCH_SINGLE_USER,
  USER_LIST,
  USER_ERROR,
  UPDATE_USER_LIST,
  CREATE_USER_LIST
} from '../constant/actionType/user';

export const createUserList = payload => {
  return {
    type: CREATE_USER_LIST,
    payload
  }
};

export const updateUserList = payload => {
  return {
    type: UPDATE_USER_LIST,
    payload
  }
};

export const userError = payload => {
  return {
    type: USER_ERROR,
    payload
  }
};

export const userList = payload => {
  return {
    type: USER_LIST,
    payload
  }
};

export const createUser = payload => {
  return {
    type: CREATE_USER,
    payload
  }
};

export const fetchSingleUser = payload => {
  return {
    type: FETCH_SINGLE_USER,
    payload
  }
}

export const updateUser = payload => {
  return {
    type: UPDATE_USER,
    payload
  }
};

export const fetchUserList = payload => {
  return {
    type: FETCH_USER_LIST,
    payload
  }
};