import React, {useState, useEffect, useRef} from 'react'
import '../../assets/scss/analytics.scss'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'
import CountUp from 'react-countup'
import moment from 'moment';

import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

function Index(props) {

    const [showFilterModal, setShowFilterModal] = useState(false)
    const dateRef = useRef();
    const [isDateApplied, setDateApplied] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment().format('DD/MM/YYYY'))
    const [dateFilter,setDateFilter] = useState({
        rangeStart: new Date(),
        rangeEnd: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        showDateRangePicker: false
    })
    const [showQueModal, setShowQuesModal] = useState(false)
    const [isAllDataApplied, setAllDataApplied] = useState(false)
    const [selectedQue, setSelectedQue] = useState('')

    const [filter, setFilter] = useState()

    useEffect(()=> {
        
    }, [])

    const dateModal =()=>{
    setDateFilter({...dateFilter, showDateRangePicker: !dateFilter.showDateRangePicker })
    }

    const changeDateFormat =(inputDate, dateFormat)=> {
        return moment(inputDate, 'YYYY-MM-DDTHH:mm:00.000Z').format(dateFormat)
    }

    const fetchTotalData = () => {        
        props.onDateChange({}, props.type)
        setAllDataApplied(true)
        setDateApplied(true)
    }

    const handleDateFilter =(isDateApplied)=>{
        // console.log("selected Date====="+JSON.stringify(d
        // console.log("rangeStart Date====="+dateRef.current.rangeStart)
        // console.log("rangeEnd Date====="+dateRef.current.rangeEnd)
        let data = {};
        if(isDateApplied){
            data = {from: `${changeDateFormat(dateFilter.rangeStart, 'YYYY-MM-DD' )} 00:00:00`, to: `${changeDateFormat(dateFilter.rangeEnd, 'YYYY-MM-DD')} 23:59:59`,}
            const startDate = changeDateFormat(dateFilter.rangeStart, 'DD/MM/YYYY')
            const endDate = changeDateFormat(dateFilter.rangeEnd, 'DD/MM/YYYY')
            if(startDate === endDate)   
                setSelectedDate(startDate)
            else setSelectedDate(`${startDate} - ${endDate}`)
            dateModal();
        } else {
            setSelectedDate(moment().format('DD/MM/YYYY'))
            data= {from: `${moment().format('YYYY-MM-DD')} 00:00:00`}
        }

        setDateApplied(isDateApplied);
        props.onDateChange(data, props.type)
        setAllDataApplied(false)
    }

    const filterModal = () => {
        setShowFilterModal(!showFilterModal)
    }

    return (
        <>
        <div className="analytic-card">
            <h1 className="d-flex" style={{justifyContent: 'space-between', textTransform: 'capitalize'}}>{props.title}
            {props.redirectionLink && <a href={props.redirectionLink}><i className='fa fa-external-link'/></a>}
            </h1>
            <div className="analytics-body">
            {props.loading ? 
            <div className='rotate dotted' style={{margin: 'auto'}}></div> :
                <>
                 <div className="d-flex" style={{gap: '10px'}}>
                    {isAllDataApplied === false ? <Button className="btn btn-light" style={{width: '90%'}} onClick={()=>dateModal()}>{selectedDate}</Button>: null}
                    {props.type === 'topThreeQue' || props.type === 'contactAttempt' ? <Button className="btn btn-light" style={{width: '90%'}} onClick={()=>fetchTotalData()}>{'Total Data'}</Button> : null}
                    {isDateApplied ? <Button className="btn btn-light" onClick={()=>handleDateFilter(false)}><i className='fa fa-times'/></Button>: null}
                </div>
                {props.singleEntry === true? 
                <>
                {props.type === 'videoDuration' ? <div style={{color: '#4466f2', fontSize: '30px',textAlign: 'center', margin: 'auto'}} >{props.data.count ? props.data.count : 0}</div> : <CountUp end={props.data.count} duration={1.5} style={{color: '#4466f2', fontSize: '36px',textAlign: 'center', margin: 'auto'}} />}
                </>
                : (props.data && props.data.length>0? 
                  <div style={{height:'160px', overflow:'auto'}}>
                    {
                      props.data.map((data, index)=> {
                        return <>
                                <div key={index} className="d-flex" style={{justifyContent: 'space-between',  fontWeight: 500, fontSize: '16px'}} onClick={()=> props.type === 'topThreeQue' && setShowQuesModal(true)}>
                                    <div style={{marginRight: 15, textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{data.name}</div>
                                    <CountUp end={data.count} duration={1.5} style={{color: '#4466f2', fontSize: '18px',fontWeight: 600,}} />
                                </div>
                              </>    
                      })
                    }
                  </div>
                  :<div className="d-flex" style={{color: '#4466f2', fontSize: '32px',textAlign: 'center', margin: 'auto'}}>No Data</div>
                )}
                </>
            }

            {props.type !== 'topThreeQue' && props.type !== 'contactAttempt' ?   <div style={{display: 'flex', justifyContent: 'space-between', justifySelf: 'baseline'}}>
                        <h5><b>Total</b></h5>
                        <h5><b>{props.total.count ? props.total.count : 0}</b></h5>
                    </div>: null}
          
            </div>
        </div>

        {/* DATEMODAL */}

        <Modal isOpen={dateFilter.showDateRangePicker} toggle={dateModal} className='modal-body' centered={true} style={{ maxWidth: 'fit-content' }}>
            <ModalHeader toggle={dateModal}>Filter by Dates</ModalHeader>

            <DateRangePicker ref={dateRef} maxDate={new Date()} minDate={new Date('1920-11-25')} scroll={{ enabled: true }} ranges={[{ startDate: dateFilter.rangeStart, endDate: dateFilter.rangeEnd, key: 'selection' }]} onChange={ranges => {setDateFilter({...dateFilter, rangeStart: ranges.selection.startDate, rangeEnd:ranges.selection.endDate})}} />
            <ModalFooter>
            <Button color='primary' onClick={()=>handleDateFilter(true)}>Done</Button>
            <Button color='danger' onClick={dateModal}>Cancel</Button>
            </ModalFooter>
        </Modal>

        {/* FILTER MODAL */}

        <Modal isOpen={showFilterModal} toggle={filterModal} className='modal-body' centered={true} style={{ maxWidth: 'fit-content' }}>
            <ModalHeader toggle={filterModal}>Filter</ModalHeader>
            <ModalBody>
                wshiuwf
            </ModalBody>
            <ModalFooter>
            <Button color='primary' >Done</Button>
            <Button color='danger' >Cancel</Button>
            </ModalFooter>
        </Modal>

        {/*QUE MODAL */}
        <Modal isOpen={showQueModal} toggle={showQueModal} className='modal-body' centered={true} style={{ maxWidth: 'fit-content'  }}>
            <ModalHeader toggle={()=> setShowQuesModal(false)}>Asked Ques</ModalHeader>

            {props.data && props.data.length>0? props.data.map((data)=> 
                <>
                    <div className="d-flex" style={{justifyContent: 'space-between',  fontWeight: 500, fontSize: '16px', padding: '5px'}}>
                        <div style={{marginRight: 15, textTransform: 'capitalize',}}>{data.name}</div>
                        <CountUp end={data.count} duration={1.5} style={{color: '#4466f2', fontSize: '18px',fontWeight: 600,}} />
                    </div>
                </> 
            ): null}
            <ModalFooter>
            <Button color='danger' onClick={()=>setShowQuesModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default Index
