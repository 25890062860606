import { call, takeLatest, all, put } from 'redux-saga/effects';

import { ACTIVITY_TRIGGER, BOT_ACTIVITY } from '../constant/actionType/activity';
import * as activityService from '../service/activity';
import * as activityAction from '../actions/activity';

function* getActivity(action) { 
  try {
    const response = yield call(activityService.eventTrigger, action);
  } catch (error) {
    console.trace(error);
  }
}

function* getBotActivitySaga(action) { 
  let errPayload={
    message:'Failed to Fetch Activity, Try again!'
  }
  try {
    const response = yield call(activityService.getBotActivity, action);
    let apiSuccess;
    if(response){
      if (response.status == 200){
        if (response.data.status == 1){
          apiSuccess = true
          let payload = {
            data: response.data.data
          }
          yield put(activityAction.botActivitySuccess(payload));
        }
      }
    }
    if (!apiSuccess)
      yield put(activityAction.botActivityFailure(errPayload));

  } catch (error) {
    console.trace(error);
    yield put(activityAction.botActivityFailure, errPayload);
  }
}

export default function* actionWatcher() {
  yield all([
    takeLatest(ACTIVITY_TRIGGER, getActivity),
    takeLatest(BOT_ACTIVITY, getBotActivitySaga),
  ])
}
